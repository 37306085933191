import React, { FC, useState } from "react";
import Layout from "../components/Layout";
import { Routes } from "../constants/routes";
import SEO from "../components/Seo";
import useMarkdown from "../hooks/useMarkdown";
import styled from "styled-components";
import { Dropup, Dropdown } from "../components/Icons";
import { AnimatePresence, motion } from "framer-motion";

const Content = styled.div`
  padding: var(--s12);
  h2 {
    margin-bottom: var(--s4);
    font-size: 2rem;
    line-height: 3.3rem;
    @media screen and (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  @media screen and (max-width: 700px) {
    padding: var(--s4);
  }
  h3,
  h4 {
    margin-top: var(--s12);
    font-size: 2rem;
    line-height: 3.3rem;
    @media screen and (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
  a,
  a:visited,
  a:link,
  a:active {
    display: block;
    color: black;
    text-decoration: underline;
  }
  p {
    font-weight: 300;
    max-width: 90ch;
    font-size: 2rem;
    line-height: 3.3rem;
    @media screen and (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  p,
  a {
    font-size: 2rem;
    line-height: 3.3rem;
    @media screen and (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  p a {
    margin-top: -0.25rem;
    display: block;
  }
  p + p {
    margin-top: var(--s4);
  }

  h3 + p + p{
    margin-top: 0;
  }

  h3 + p + p + h3 + p + p{
    margin-top: var(--s6);

  }

  h4 {
    margin-top: var(--s12);
  }
`;

const Accordion = styled.button`
  background: none;
  border: none;
  width: 100%;
  display: flex;
  margin-top: var(--s24);
  align-items: center;
  cursor: pointer;
  outline: none;
  h3 {
    margin-top: 0 !important;
  }
  svg {
    fill: black;
    height: var(--s6);
    width: var(--s6);
    margin-left: var(--s2);
  }
`;
const KontaktPage: FC = () => {
  const kontakt = useMarkdown("kontakt")[0];
  const impressium = useMarkdown("impressium")[0];
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  return (
    <Layout activePage={Routes.KONTAKT}>
      <SEO title="Kontakt" />
      <Content>
        <div dangerouslySetInnerHTML={{ __html: kontakt.html }} />
        <Accordion onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
          <h3 style={{ marginTop: "5rem", textAlign: "left" }}>Urheberrechtshinweis und Impressum </h3>
          {isAccordionOpen ? <Dropup /> : <Dropdown />}
        </Accordion>
        <AnimatePresence>
          {isAccordionOpen && (
            <motion.div
              transition={{ damping: 10 }}
              initial={{ height: 0, opacity: 0, y: 10 }}
              animate={{ height: "auto", opacity: 1, y: 0 }}
              exit={{ height: 0, opacity: 0, y: 10 }}
              dangerouslySetInnerHTML={{ __html: impressium.html }}
            />
          )}
        </AnimatePresence>
      </Content>
    </Layout>
  );
};

export default KontaktPage;
