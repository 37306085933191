import { graphql, useStaticQuery } from "gatsby";
import { MarkdownQuery } from "../../graphql-types";

const QUERY = graphql`
  query Markdown {
    allMarkdownRemark {
      nodes {
        id
        frontmatter {
          title
        }
        html
      }
    }
  }
`;

const useMarkdown = (title: "fotos" | "biografie" | "ausstellungen" | "publikationen" | "kontakt" | "impressium" | "kunstsammlungen") => {
  const data = useStaticQuery<MarkdownQuery>(QUERY);
  return data.allMarkdownRemark.nodes
    .filter(n => n.frontmatter && n.frontmatter.title === title)
    .map(n => ({
      id: n.id,
      html: n.html || "",
    }));
};

export default useMarkdown;
